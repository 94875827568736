<template>
  <div id="singleApply">
    <header-nav :content="`${$t('pages_router_021')} / ${ $t('pages_router_024')}`" />
    <div class="common-card-body">
      <div class="single-header" @click="$router.replace('/funds/inbounds/batch_cny')">
        <swap-outlined class="ic-switch" />
        {{ $t('pages_pay_013') }}
      </div>
      <div style="display: flex; justify-content: center">
        <a-steps :current="1" size="small" class="single-apply-steps">
          <a-step :title="$t('pages_pay_014')" />
          <a-step :title="$t('pages_pay_015')" />
          <a-step :title="$t('pages_pay_016')" />
        </a-steps>
      </div>
      <div style="display: flex; justify-content: center">
        <a-form
          ref="applyRef"
          class="ant-round-form single-apply-form"
          layout="vertical"
          :model="applyForm"
          :rules="applyRules"
          @finish="applySubmit"
          @finishFailed="submitError"
        >
          <a-form-item name="payeeId">
            <template #label>
              <span>{{ $t('pages_pay_028') }}</span>
              <div class="area-add flex-align" @click="$router.push('/funds/inbounds/payee_edit')">
                <img src="~@/assets/icons/ic-user-add.png">
              </div>
            </template>
            <div class="person-info flex-align" @click="payeeChange">
              <img v-if="payeeInfo.code" class="img-add" src="~@/assets/icons/ic-user.png">
              <span style="margin-left: 12px" class="ellipsis">{{ payeeInfo.name }}</span>
              <div v-if="payeeInfo.code" style="margin-left: auto" class="flex-align">
                <img class="img-country" :src="getCountryLogo(payeeInfo.currency)">
                <span style="margin-left: 9px">{{ payeeInfo.currency }}</span>
                <span style="margin-left: 15px" class="ellipsis">{{ payeeInfo.branchName }}·{{ payeeInfo.bankNum }}</span>
                <up-outlined v-if="payeeSelected" class="ic-select" />
                <down-outlined v-else class="ic-select" />
              </div>
              <div v-show="payeeSelected" class="select-area" @click="e => e.stopPropagation()">
                <person-select
                  ref="payeeRef"
                  method-type="inbound"
                  @select="payeeSelect"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item v-show="isFI" name="draweeId" :rules="{required: isFI, validator: validatePerson, trigger: 'blur' }">
            <template #label>
              <span>{{ $t('pages_pay_029') }}</span>
              <div class="area-add flex-align" @click="$router.push('/funds/payer_manage')">
                <img src="~@/assets/icons/ic-user-add.png">
              </div>
            </template>
            <div class="person-info flex-align" @click="payerChange">
              <img v-if="payerInfo.code" class="img-add" src="~@/assets/icons/ic-user.png">
              <span style="margin-left: 12px" class="ellipsis">{{ payerInfo.name }}</span>
              <div v-if="payerInfo.code" style="margin-left: auto" class="flex-align">
                <span style="margin-left: 15px" class="ellipsis">{{ payerInfo.bankName }}·{{ payerInfo.bankNum }}</span>
                <up-outlined v-if="payerSelected" class="ic-select" />
                <down-outlined v-else class="ic-select" />
              </div>
              <div v-show="payerSelected" class="select-area" @click="e => e.stopPropagation()">
                <person-select
                  ref="payerRef"
                  drop-type="company"
                  @select="payerSelect"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item name="tradeType">
            <template #label>
              <div class="flex-align" style="width: 100%">
                <span>{{ $t('pages_pay_030') }}</span>
                <div v-if="applyForm.tradeType === '121011'" class="amount-text">
                  <info-circle-filled class="ic-tip" />
                  <span>{{ $t('pages_pay_031') }}: CNY {{ parseMoney(availabelQuota) }}</span>
                </div>
              </div>
            </template>
            <a-select
              v-model:value="applyForm.tradeType"
              :placeholder="$t('common_text_005')"
            >
              <a-select-option
                v-for="item in tradeTypeList"
                :key="item.code"
                :value="item.code"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            v-show="applyForm.tradeType"
            name="settlementType"
            :label="$t('pages_pay_036')"
          >
            <div class="settle-list">
              <div
                v-if="['121011', '122030'].includes(applyForm.tradeType)"
                class="settle-card"
                :class="applyForm.settlementType === '10' ? 'active' : ''"
                @click="settleCardChange('10')"
              >
                <div class="tip">{{ $t('pages_pay_037') }}</div>
                <div class="title">{{ $t('pages_pay_038') }}</div>
                <div class="content">
                  <div>{{ $t('pages_pay_039') }}</div>
                  <div style="margin-top: 5px">{{ $t('pages_pay_040') }}</div>
                  <div>{{ $t('pages_pay_041') }}</div>
                  <div v-if="fastStandard" style="margin-top: 8px">
                    <div>{{ $t('pages_pay_042') }}</div>
                    <div v-if="fastStandard.feeAmt && fastStandard.feeRate">{{ fastStandard.feeAmt + fastStandard.feeCurrency }}/{{ $t('pages_pay_295') }} + {{ $t('pages_pay_294') }}*{{ fastStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-else-if="!fastStandard.feeAmt && fastStandard.feeRate">{{ $t('pages_pay_294') }}*{{ fastStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-else>{{ fastStandard.feeAmt + fastStandard.feeCurrency }}/{{ $t('pages_pay_295') }}</div>
                  </div>
                </div>
              </div>
              <div
                class="settle-card"
                style="margin-right: 0"
                :class="applyForm.settlementType === '00' ? 'active' : ''"
                :style="{width: ['121011', '122030'].includes(applyForm.tradeType) ? '' : '328px'}"
                @click="settleCardChange('00')"
              >
                <div class="title">{{ $t('pages_pay_043') }}</div>
                <div class="content">
                  <div>{{ $t('pages_pay_039') }}</div>
                  <div style="margin-top: 5px">{{ $t('pages_pay_044') }}</div>
                  <div>{{ $t('pages_pay_045') }}</div>
                  <div v-if="normalStandard" style="margin-top: 8px">
                    <div>{{ $t('pages_pay_042') }}</div>
                    <div v-if="normalStandard.feeAmt && normalStandard.feeRate">{{ normalStandard.feeAmt + normalStandard.feeCurrency }}/{{ $t('pages_pay_295') }} + {{ $t('pages_pay_294') }}*{{ normalStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-else-if="!normalStandard.feeAmt && normalStandard.feeRate">{{ $t('pages_pay_294') }}*{{ normalStandard.feeRate }}%/{{ $t('pages_pay_295') }}</div>
                    <div v-else>{{ normalStandard.feeAmt + normalStandard.feeCurrency }}/{{ $t('pages_pay_295') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </a-form-item>
          <a-row>
            <a-col :span="11">
              <a-form-item name="payAmount">
                <template #label>
                  <div class="flex-align" style="width: 100%">
                    <span>{{ $t('pages_pay_032') }}</span>
                    <span v-if="maxSettleAmount !== null" class="amount-text">
                      {{ $t('pages_pay_034') }} {{ parseMoney(maxSettleAmount) }}
                    </span>
                  </div>
                </template>
                <number-input
                  v-model:input-num="applyForm.payAmount"
                  input-size="large"
                  :input-style="{paddingRight: '50%'}"
                  @update="payAmountChange"
                />
                <span
                  v-if="maxSettleAmount !== null"
                  class="amount-text full-all"
                  @click="fullAllMount"
                >{{ $t('pages_pay_035') }}</span>
                <a-form-item-rest>
                  <a-select
                    v-model:value="curInfo.payCur"
                    size="large"
                    show-search
                    option-filter-prop="label"
                    class="area-select"
                    :bordered="false"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in payCurList"
                      :key="item.currency"
                      :value="item.currency"
                      :label="`${item.currency} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.currency)" class="select-img-country">
                      <span>{{ item.currency }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item-rest>
              </a-form-item>
            </a-col>
            <a-col :span="2" class="flex-align" style="justify-content: center">
              <img class="ic-single" src="~@/assets/icons/ic-single.png">
            </a-col>
            <a-col :span="11">
              <a-form-item name="receiveAmount" :label="$t('pages_pay_033')">
                <number-input
                  v-model:input-num="applyForm.receiveAmount"
                  input-size="large"
                  @update="receiveAmountChange"
                />
                <a-form-item-rest>
                  <a-select
                    v-model:value="curInfo.receiveCur"
                    size="large"
                    disabled
                    show-search
                    option-filter-prop="label"
                    class="area-select"
                    :bordered="false"
                  >
                    <template #suffixIcon>
                      <img src="~@/assets/icons/ic-choose.png" class="ic-select">
                    </template>
                    <a-select-option
                      v-for="item in receiveCurList"
                      :key="item.currencyCode"
                      :value="item.currencyCode"
                      :label="`${item.currencyCode} - ${item.currencyName}`"
                    >
                      <img :src="getCountryLogo(item.imgName || item.currencyCode)" class="select-img-country">
                      <span>{{ item.currencyCode }}</span>
                    </a-select-option>
                  </a-select>
                </a-form-item-rest>
              </a-form-item>
            </a-col>
          </a-row>
          <div v-show="curInfo.payCur !== 'CNY'" style="margin-bottom: 24px">
            <div class="exchange-rate flex-align">
              <a-spin v-if="loading" :tip="$t('pages_exchange_030')" />
              <div v-else-if="rate" class="rate-info flex-align">
                <img src="~@/assets/icons/ic-great.png">
                <div class="rate flex-align">
                  <a-tooltip :title="$t('pages_pay_046')">
                    <span>{{ $t('pages_exchange_028') }}</span>
                    <span style="margin-left: 5px">1 {{ arithmetic === 'DIV' ? curInfo.receiveCur : curInfo.payCur }} = {{ rate }} {{ arithmetic === 'DIV' ? curInfo.payCur : curInfo.receiveCur }}</span>
                  </a-tooltip>
                </div>
                <div class="update-date">
                  {{ $t('pages_exchange_029') }} {{ queryDate }}
                </div>
              </div>
              <div v-else class="rate-error">{{ resMsg }}</div>
              <div v-if="!loading" class="refresh-process">
                <count-down
                  :valid-time="validTime"
                  :trade-currency="`${curInfo.payCur}${curInfo.receiveCur}`"
                  @refresh="loadExchangeRate"
                />
              </div>
            </div>
            <div v-if="rate" class="explain">{{ $t('pages_pay_046') }}</div>
          </div>

          <a-spin v-show="feeLoading" :tip="$t('pages_pay_050')" style="margin-top: 18px" />
          <div v-show="feeAmount !== null" class="fee-detail flex-align">
            <img src="~@/assets/icons/ic-money.png">
            <span>{{ $t('pages_pay_047') }}</span>
            <span style="margin-left: 5px">{{ parseMoney(feeAmount) }} {{ feeCurrency }}</span>
            <a-tag style="margin-left: 8px" color="pink">{{ feePattern === '00' ? $t('pages_pay_048') : $t('pages_pay_049') }}</a-tag>
          </div>
          <a-form-item
            v-if="applyForm.tradeType && ((applyForm.tradeType === '121011' && ecommerceUpload) || applyForm.tradeType !== '121011')"
            name="filePath"
            :label="$t('pages_pay_051')"
          >
            <upload-auto
              ref="uploadRef"
              v-model:file-content="applyForm.filePath"
              :is-down="matchType(applyForm.tradeType)"
              :accept="matchType(applyForm.tradeType) ? '.xls,.xlsx' : '.zip'"
              :notes="matchType(applyForm.tradeType) ? $t('pages_pay_054') : ''"
              :explain="matchType(applyForm.tradeType) ? $t('pages_pay_052') : $t('pages_pay_053')"
              :max-count="1"
              @down="downTemplate"
            />
          </a-form-item>
          <a-form-item name="merSingleNo" :label="$t('pages_pay_056')">
            <a-input v-model:value="applyForm.merSingleNo" :placeholder="$t('pages_pay_058')" allow-clear />
          </a-form-item>
          <a-form-item name="tradeContent" :label="$t('pages_pay_057')">
            <a-textarea v-model:value="applyForm.tradeContent" class="input-primary" :placeholder="$t('common_text_006')" allow-clear />
          </a-form-item>
          <a-form-item v-if="getUsedCheck(pageLabs, 150101)" style="text-align: right">
            <a-button
              :loading="submitLoading"
              html-type="submit"
              type="primary"
              shape="round"
              style="min-width: 138px; margin-top: 24px"
            >{{ $t('common_text_002') }}</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import PersonSelect from '@/components/person-select'
import CountDown from '@/components/count-down'
import UploadAuto from '@/components/upload-auto'
import NumberInput from '@/components/number-input'
import { ref, reactive, toRefs, computed, watch, onUnmounted, createVNode } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message, Modal } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import { _pay, _receive, _exchange, _account } from '@/api'
import { getCountryLogo, parseMoney, getAttrStatusList, evalMathStr, matchType, getUsedCheck } from '@/util'
import i18n from '@/locale'
export default {
  name: 'SingleApply',
  components: {
    'header-nav': HeaderNav,
    'person-select': PersonSelect,
    'count-down': CountDown,
    'upload-auto': UploadAuto,
    'number-input': NumberInput
  },
  setup () {
    let timer = null
    const payeeRef = ref(null)
    const payerRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const applyRef = ref(null)
    const uploadRef = ref(null)

    const validatePerson = (rule, value) => {
      const { payeeId, draweeId } = state.applyForm
      if (!payeeId && rule.field === 'payeeId') return Promise.reject(i18n.global.t('pages_pay_017'))
      if (!draweeId && rule.field === 'draweeId') return Promise.reject(i18n.global.t('pages_pay_018'))
      return Promise.resolve()
    }
    const validatorAmount = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_pay_019'))
      if (!(!isNaN(parseFloat(value)) && isFinite(value))) return Promise.reject(i18n.global.t('pages_pay_020'))
      if (state.curInfo.payCur === 'JPY' && String(value).indexOf('.') !== -1 && rule.field === 'payAmount') {
        return Promise.reject(i18n.global.t('pages_pay_021'))
      }
      if (String(value).split('.')[0].length > 16 || (String(value).split('.')[1] && String(value).split('.')[1].length > 2)) {
        return Promise.reject(i18n.global.t('pages_pay_022'))
      }
      if (value > state.maxSettleAmount && rule.field === 'payAmount' && state.maxSettleAmount !== null) {
        return Promise.reject(i18n.global.t('pages_pay_023'))
      }
      return Promise.resolve()
    }
    const validatorTradeType = (rule, value) => {
      if (!value) return Promise.reject(i18n.global.t('pages_pay_024'))
      if (state.applyForm.filePath) return Promise.resolve()
      if ((state.applyForm.receiveAmount > state.availabelQuota) && value === '121011') {
        return Promise.reject(i18n.global.t('pages_pay_025'))
      }
      return Promise.resolve()
    }
    const validatorUpload = (rule, value) => {
      const { filePath, tradeType, receiveAmount } = state.applyForm
      if (!filePath) {
        if (tradeType === '121011') {
          if (receiveAmount <= state.availabelQuota) return Promise.resolve()
          else return Promise.reject(i18n.global.t('pages_pay_026'))
        } else {
          return Promise.reject(i18n.global.t('pages_pay_026'))
        }
      }
      return Promise.resolve()
    }
    const validatorRadio = (rule, value) => {
      if (!state.applyForm.settlementType) return Promise.reject(i18n.global.t('pages_pay_027'))
      return Promise.resolve()
    }

    const state = reactive({
      isFI: false,
      loading: true,
      feeLoading: false,
      submitLoading: false,
      maxSettleAmount: null,
      availabelQuota: null,
      payeeSelected: false,
      payerSelected: false,
      payFinally: false,
      receiveFinally: false,
      ecommerceUpload: false,
      normalStandard: null,
      fastStandard: null,
      feeAmount: null,
      feePattern: '00',
      feeCurrency: '',
      rate: '',
      arithmetic: '',
      queryDate: '',
      resMsg: '',
      validTime: 12,
      payeeInfo: {},
      payerInfo: {},
      payees: [],
      payers: [],
      pageLabs: [],
      payCurList: [],
      receiveCurList: [],
      businessType1: route.query.businessType1,
      businessType2: route.query.businessType2,
      curInfo: {
        payCur: 'USD',
        receiveCur: 'CNY'
      },
      applyForm: {
        payeeId: route.query.payeeId || '',
        draweeId: route.query.payerId || '',
        tradeType: null,
        payAmount: null,
        receiveAmount: null,
        fileName: '',
        filePath: '',
        settlementType: '00',
        merSingleNo: '',
        tradeContent: ''
      },
      applyRules: {
        payeeId: [{ required: true, validator: validatePerson, trigger: 'blur' }],
        tradeType: [{ required: true, validator: validatorTradeType }],
        payAmount: [{ required: true, validator: validatorAmount }],
        receiveAmount: [{ required: true, validator: validatorAmount }],
        filePath: [{ required: true, validator: validatorUpload }],
        settlementType: [{ required: true, validator: validatorRadio }],
        merSingleNo: [{ max: 20, message: i18n.global.t('common_text_025') }],
        tradeContent: [{ max: 256, message: i18n.global.t('common_text_026') }]
      }
    })

    state.tradeTypeList = computed(() => {
      if ((state.businessType1 && !state.businessType2)) {
        return getAttrStatusList('tradeTradeCode').sort((a, b) => {
          if (b.code === '121010' && a.code !== '121010') return -1
          if (a.code === '121010' && b.code !== '121010') return 1
        }).filter(item => item.code !== '121010')
      } else if ((!state.businessType1 && state.businessType2)) {
        return getAttrStatusList('serviceTradeCode')
      } else {
        return getAttrStatusList('tradeCode').sort((a, b) => {
          if (b.code === '121010' && a.code !== '121010') return -1
          if (a.code === '121010' && b.code !== '121010') return 1
        }).filter(item => item.code !== '121010')
      }
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    watch(() => state.applyForm.tradeType,
      (value) => {
        if (!value) return
        if (['121011', '122030'].includes(value)) {
          state.applyForm.settlementType = ''
          if (value === '121011' && state.applyForm.receiveAmount > state.availabelQuota) {
            state.ecommerceUpload = true
          }
          getMaxSettleAmount()
          getFeeAmount()
        } else {
          settleCardChange('00')
        }
        state.applyForm.filePath = ''
        if (uploadRef.value) {
          uploadRef.value.fileData = []
          uploadRef.value.fileList = []
        }
        getReceiveStandard()
      }
    )

    watch(() => state.curInfo.payCur,
      async () => {
        getMaxSettleAmount()
        await loadExchangeRate()
        if (state.payFinally) await computeReceiveAmount()
        else await computePayAmount()
        getFeeAmount()
      }
    )

    watch(() => state.applyForm.filePath,
      (value) => {
        if (value) {
          let fileName = ''
          const a = value.split('/')
          const b = a[a.length - 1]
          const c = b.split('_')
          for (let i = 0; i < c.length; i++) {
            if (i === 0) continue
            fileName = fileName ? `${fileName}_${c[i]}` : c[i]
          }
          state.applyForm.fileName = fileName.trim()
          if (state.applyForm.tradeType === '121011') applyRef.value.validateFields('tradeType')
        } else {
          state.applyForm.fileName = ''
        }
      }
    )

    const fullAllMount = () => {
      state.applyForm.payAmount = state.maxSettleAmount
      payAmountChange()
      applyRef.value.validateFields('payAmount')
    }

    const payAmountChange = () => {
      state.payFinally = true
      state.receiveFinally = false
      computeReceiveAmount()
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        getFeeAmount()
      }, 300)
    }

    const receiveAmountChange = () => {
      state.receiveFinally = true
      state.payFinally = false
      computePayAmount()
      clearInterval(timer)
      timer = setInterval(() => {
        clearInterval(timer)
        getFeeAmount()
      }, 300)
    }

    const payeeChange = () => {
      state.payeeSelected = !state.payeeSelected
    }

    const payerChange = () => {
      state.payerSelected = !state.payerSelected
    }

    const payeeSelect = (selectId) => {
      state.businessType1 = ''
      state.businessType2 = ''
      state.applyForm.tradeType = null
      state.payeeInfo = state.payees.find(item => item.code === selectId)
      state.applyForm.payeeId = selectId
      const { businessType } = state.payeeInfo
      if (businessType.indexOf('00') !== -1) state.businessType1 = '00'
      if (businessType.indexOf('01') !== -1) state.businessType2 = '01'
      applyRef.value.validateFields('payeeId')
      payeeChange()
    }

    const payerSelect = (selectId) => {
      state.payerInfo = state.payers.find(item => item.code === selectId)
      state.applyForm.draweeId = selectId
      applyRef.value.validateFields('draweeId')
      payerChange()
    }

    const submitError = () => {
      message.error(i18n.global.t('common_text_030'))
    }

    const applySubmit = async () => {
      if (state.feeAmount === null) return message.error(i18n.global.t('pages_pay_059'))
      if (!state.rate) return message.error(i18n.global.t('pages_pay_060'))
      state.submitLoading = true
      const { payCur, receiveCur } = state.curInfo
      const { payeeId, draweeId, payAmount, receiveAmount } = state.applyForm
      const data = Object.assign({}, state.applyForm, {
        payee: payeeId,
        payer: draweeId,
        amount: state.payFinally ? payAmount : receiveAmount,
        amountMark: state.payFinally ? 'remit' : 'arrived',
        remitCurrency: payCur,
        arrivalCurrency: receiveCur,
        rate: state.rate,
        arithmetic: state.arithmetic
      })
      _pay.singlePayApply(data)
        .then(res => {
          state.submitLoading = false
          if (res.data.succ) {
            router.push({ path: '/funds/apply_success', query: { key: 'inbound' }})
          } else if (res.data.resCode === '020015') {
            Modal.confirm({
              title: i18n.global.t('pages_pay_061'),
              content: i18n.global.t('pages_pay_062'),
              icon: createVNode(CloseCircleOutlined, { style: { color: '#ff4d4f' }}),
              okText: i18n.global.t('common_text_019'),
              onOk: () => {
                window.open(res.data.data.failFileName)
              }
            })
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => console.log(err))
    }

    const settleCardChange = (settlementType) => {
      state.applyForm.settlementType = settlementType
      applyRef.value.validateFields('settlementType')
      getMaxSettleAmount()
      getFeeAmount()
    }

    const getFeeAmount = async () => {
      const { tradeType, settlementType, payAmount, receiveAmount } = state.applyForm
      const { payCur } = state.curInfo
      state.feeAmount = null
      state.feePattern = ''
      state.feeCurrency = ''
      if (tradeType && settlementType && payAmount && receiveAmount) {
        if ((!(!isNaN(parseFloat(payAmount)) && isFinite(payAmount)))) return
        state.feeLoading = true
        const query = {
          tradeType,
          tradeCurrency: payCur,
          tradeAmount: payAmount,
          settlementType
        }
        const res = await _pay.getFeeAmount(query)
        if (res.data.succ) {
          const { feeAmt, feePattern, feeCurrency } = res.data.data
          state.feeAmount = feeAmt
          state.feePattern = feePattern
          state.feeCurrency = feeCurrency
        } else {
          message.destroy()
          message.error(res.data.resMsg)
        }
        state.feeLoading = false
      }
    }

    const getMaxSettleAmount = async () => {
      state.maxSettleAmount = null
      const { tradeType, settlementType } = state.applyForm
      const { payCur } = state.curInfo
      if (tradeType && settlementType) {
        const query = {
          tradeType,
          settlementType,
          tradeCurrency: payCur
        }
        const res = await _pay.getSettleMaxAmount(query)
        if (res.data.succ) {
          state.maxSettleAmount = res.data.data
        } else {
          message.destroy()
          message.error(res.data.resMsg)
        }
        applyRef.value.validateFields('payAmount')
      }
    }

    const getReceiveStandard = async () => {
      state.normalStandard = null
      state.fastStandard = null
      const res = await _pay.getFeeReceiveConfig({ tradeType: state.applyForm.tradeType })
      if (res.data.succ && res.data.data) {
        for (const item of res.data.data) {
          if (item.settlementType === '00') state.normalStandard = item
          else state.fastStandard = item
        }
      }
    }

    const computePayAmount = () => {
      const { receiveAmount } = state.applyForm
      const { payCur } = state.curInfo
      if (!state.arithmetic) return
      if (!receiveAmount) {
        state.applyForm.payAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(receiveAmount)) && isFinite(receiveAmount)))) return
      let payAmount = 0
      if (state.arithmetic === 'DIV') {
        if (payCur === 'JPY') {
          payAmount = Math.round((evalMathStr(receiveAmount + '*' + state.rate) * 1))
        } else {
          payAmount = Math.round((evalMathStr(receiveAmount + '*' + state.rate) * 1) * 100) / 100
        }
      } else {
        if (payCur === 'JPY') {
          payAmount = Math.round((evalMathStr(receiveAmount + '/' + state.rate) * 1))
        } else {
          payAmount = Math.round((evalMathStr(receiveAmount + '/' + state.rate) * 1) * 100) / 100
        }
      }
      state.applyForm.payAmount = payAmount
      applyRef.value.validateFields('payAmount')
      compareAvailabelQuota()
    }

    const computeReceiveAmount = () => {
      const { payAmount } = state.applyForm
      const { receiveCur } = state.curInfo
      if (!state.arithmetic) return
      if (!payAmount) {
        state.applyForm.receiveAmount = null
        return
      }
      if ((!(!isNaN(parseFloat(payAmount)) && isFinite(payAmount)))) return
      let receiveAmount = 0
      if (state.arithmetic === 'DIV') {
        if (receiveCur === 'JPY') {
          receiveAmount = Math.round((evalMathStr(payAmount + '/' + state.rate) * 1))
        } else {
          receiveAmount = Math.round((evalMathStr(payAmount + '/' + state.rate) * 1) * 100) / 100
        }
      } else {
        if (receiveCur === 'JPY') {
          receiveAmount = Math.round((evalMathStr(payAmount + '*' + state.rate) * 1))
        } else {
          receiveAmount = Math.round((evalMathStr(payAmount + '*' + state.rate) * 1) * 100) / 100
        }
      }
      state.applyForm.receiveAmount = receiveAmount
      applyRef.value.validateFields('receiveAmount')
      compareAvailabelQuota()
    }

    const compareAvailabelQuota = () => {
      const { tradeType, receiveAmount } = state.applyForm
      if (tradeType === '121011') {
        applyRef.value.validateFields('tradeType')
        if (receiveAmount > state.availabelQuota) {
          state.ecommerceUpload = true
        }
      }
    }

    const loadAvailabelQuota = async () => {
      const res = await _pay.getAvailabelAmount()
      if (res.data.succ) state.availabelQuota = res.data.data
    }

    const loadMerchantKyc = async () => {
      const res = await _pay.findByMerchantKycEnrol()
      if (res.data.succ) {
        state.isFI = res.data.data.merchantType === 'FI'
        if (state.isFI) loadPayerList()
      }
    }

    const loadPayeeList = async () => {
      const { payeeId } = state.applyForm
      const res = await _pay.getPayeeList({ pageEnable: false, status: '01' })
      if (res.data.succ) {
        state.payees = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            code: String(item.id),
            name: item.payeeName
          })
        })
        if (payeeId) state.payeeInfo = state.payees.find(item => item.code === payeeId) || {}
        payeeRef.value.initData(payeeId, state.payees)
      }
    }

    const loadPayerList = async () => {
      const { draweeId } = state.applyForm
      const res = await _pay.getPayerList({ pageEnable: false })
      if (res.data.succ) {
        state.payers = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            code: String(item.id),
            name: item.senderName
          })
        })
        if (draweeId) state.payerInfo = state.payers.find(item => item.code === draweeId) || {}
        payerRef.value.initData(draweeId, state.payers)
      }
    }

    const loadPayCurList = async () => {
      const res = await _pay.getCurAccount()
      if (res.data.succ) {
        state.payCurList = res.data.data
      }
    }

    const loadReceiveCurList = async () => {
      const res = await _receive.getAllCurrency()
      if (res.data.succ) {
        state.receiveCurList = res.data.data
      }
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1501 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    const loadExchangeRate = async () => {
      state.loading = true
      state.rate = ''
      state.arithmetic = ''
      state.queryDate = ''
      if (state.curInfo.payCur === 'CNY') {
        state.rate = 1
        state.arithmetic = 'DIV'
        return
      }
      const res = await _exchange.getExchangeRate({ buyCurrency: state.curInfo.receiveCur, sellCurrency: state.curInfo.payCur })
      if (res.data.succ) {
        const { data } = res.data
        if (data.sellCurrency !== state.curInfo.payCur) return
        state.resMsg = ''
        state.rate = data.rate
        state.arithmetic = data.arithmetic
        state.queryDate = data.queryDate
        state.validTime = data.validTime * (data.validUnit === 'HH' ? 3600 : data.validUnit === 'MM' ? 60 : 1)
      } else {
        state.resMsg = res.data.resMsg
        state.validTime = 12
        state.applyForm.payAmount = null
        state.applyForm.receiveAmount = null
      }
      state.loading = false
    }

    const downTemplate = async (callback = () => {}) => {
      await _pay.downReducingMaterialTemplate({ bizType: state.applyForm.tradeType })
      callback()
    }

    loadExchangeRate()
    loadPayeeList()
    loadPayCurList()
    loadReceiveCurList()
    loadMerchantKyc()
    loadAvailabelQuota()
    loadChildrenMenu()

    return {
      applyRef,
      payeeRef,
      payerRef,
      uploadRef,
      getCountryLogo,
      getUsedCheck,
      parseMoney,
      matchType,
      payeeChange,
      payerChange,
      payeeSelect,
      payerSelect,
      payAmountChange,
      receiveAmountChange,
      fullAllMount,
      settleCardChange,
      loadExchangeRate,
      downTemplate,
      applySubmit,
      submitError,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../styles/apply-single.less';
</style>

